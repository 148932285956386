.card-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px; 
  }
  
  .card {
    display: flex;
    width: 19%;
    background-color: #F7F6F6;
    flex-direction: column;
    align-items: center;
  }
  
  .card-image {
    height: 163px;
    margin-top: 3em;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    scale: 0;
    transition: all ease 0.5s ;
  }

.card-Image-1{
  transition-delay: 0s;
}
.card-Image-2{
  transition-delay: 0.2s;
}
.card-Image-3{
  transition-delay: 0.4s;
}
.card-Image-4{
  transition-delay: 0.6s;
}
.card-Image-5{
  transition-delay: 0.8s;
}

  .card-image.active{
    scale: 1;
  }
  
  .card-content {
    padding: 20px;
  }
  
  .card-title {
    text-align: left;
    margin-top: 3em;
  }
  
  .card-paragraph {
    margin-top: 10px;
    text-align: left;
  }


  @media (max-width : 1024px) {

    .card-container {
      justify-content: center;
      flex-wrap: wrap; 
      row-gap: 30px;
      column-gap: 1%;
    }

    .card {
      width: 32%;
    }
    
    .card-title {
      text-align: left;
      margin-top: 1em;
    }

  }


  @media (max-width : 990px) {

    .card-container {
      justify-content: flex-start;
      flex-wrap: wrap; 
      row-gap: 30px;
      column-gap: 1%;
    }

    .card {
      width: 32%;
    }
    
    .card-title {
      text-align: left;
      margin-top: 1em;
    }

  }

  @media (max-width : 768px) {
    .card-container {
      justify-content: flex-start;
      flex-wrap: wrap; 
      row-gap: 20px;
      column-gap: 1%;
    }

    .card {
      width: 48%;
    }
    
  }

  @media (max-width : 600px){
    .card-container {
      row-gap: 20px;
      column-gap: 1%;
    }

    .card {
      width: 100%;
    }
  }
  
  