.prodmax-banner{
    height: 100vh;
    position: relative;
}

.background-video {
    position: absolute;
    top: 0;
    left: -10px;
    width: calc(100% + 10px);
    height: 100%;
    object-fit: cover; 
    z-index: -1; 
  }

.home-banner{
    height: 100vh;
    display: flex;
    align-items: center;
    text-align: left;
}

.banner-heading{
    width: 80%;
    color: #fff;
}


.banner-image-container{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 30%;
    height: 30%;
}

.banner-image-container img.logo-banner{
    width: 90%;
}

.banner-image-container img.boxes{
    position: absolute;
    z-index: -1;
    width: 100%;
}

.info-container{
    position: relative;
    z-index: 100;
}



.our-services{
    background-color: #F7F6F6;
    width: 100vw;
    padding-top: 80px;
    padding-bottom: 20px;
}

.our-services p{
    line-height: 150%;
}

.our-services .heading{
    margin-bottom: 82px;
}

.our-services .heading h1{
    margin-bottom: 30px;
}

.our-services .heading p{
    text-align: left;
}

.customer-aq{
    text-align: left;
    margin-bottom: 50px;
}

.customer-aq h2{
    margin-bottom: 20px;
}

.animation-container{
    display: flex;
    gap: 120px;
    margin-left: 60px;
}

.animation-container img{
    width: 152px;
}


.animation-container .image-container{
    display: flex;
    justify-content: center;
    align-items: center;
}


.animation-container .image-container svg.raised{
    height: 200px;
    width: 200px;
    position: absolute;
    transform: translateX(0);
}

.animation-container .image-container svg.equals{
    height: 200px;
    width: 200px;
    position: absolute;
    transform: translateX(0);

}

.animation-container .image-container .svg{
    height: 200px;
    width: 200px;
    position: absolute;
    transform: translate(9.3%, 18.4px);
    scale: 1;
}


        
        
@keyframes x-to-plus-1 {
            
    0%, 35% { 
      d: path("M138,1 L138,2 L137,2 L137,2 L137,2 L136,3 L136,3 L134,5 L131,10 L126,16 L120,24 L113,32 L107,41 L101,49 L96,55 L93,59 L91,61 L91,62 L91,63 L91,63 L91,64 L90,65 L91,65 L91,66 L91,67 L91,67 L91,68 L92,69 L94,71 L96,74 L99,78 L102,82 L105,85 L107,89 L110,92 L111,94 L112,95 L113,95 L113,95 L113,95 L114,96 L114,96 L115,96 L115,96 L115,96 L116,96 L116,96 L117,96 L117,96 L118,96 L118,96 L119,96 L119,95 L120,95 L120,95 L121,95 L121,94 L123,91 L128,85 L135,76 L144,65 L153,53 L162,41 L171,30 L178,20 L183,14 L184,12 L185,11 L185,11 L185,10 L185,10 L185,9 L185,8 L185,8 L185,7 L185,7 L185,6 L184,5 L184,4 L183,3 L182,2 L182,2 L181,1 L180,1 L179,0 L178,0 L176,0 L176,0 L175,0 L174,0 L173,0 L171,0 L169,0 L166,0 L164,0 L161,0 L159,0 L158.624,0.293091 L156,0 L153,0 L151,0 L148,0 L146,1 L145,1 L143,1 L142,1 L141,1 L140,1 L140,1 L139,1 L139,1 L138,1"); 
      fill: #1209E7;
       
    }
    55%, 100% { 
      d: path("M106,2 L107,2 L108,2 L109,3 L109,3 L110,3 L110,4 L111,5 L111,5 L111,6 L111.158,6.8421 L111,10 L111,14 L111,18 L111,22 L111,26 L111,30 L111,34 L111,38 L111,42 L111,46 L111,50 L111,54 L111,58 L111,62 L111,66 L111,69 L111,70 L111,71 L111,72 L110,72 L110,73 L109,73 L109,74 L108,74 L107,74 L106.316,74.2729 L103,74 L99,74 L95,74 L91,74 L87,74 L83,74 L79,74 L77,74 L75,74 L74,74 L73,74 L73,74 L72,73 L71,73 L71,72 L71,72 L70,71 L70,70 L70,69.4308 L70,66 L70,64 L70,62 L70,60 L70,58 L70,56 L70,54 L70,52 L70,50 L70,48 L70,46 L70,44 L70,42 L70,40 L70,38 L70,36 L70,34 L70,32 L70,30 L70,28 L70,26 L70,24 L70,22 L70,20 L70,18 L70,16 L70,14 L70,12 L70,10 L70,6.8421 L70,7 L70,6 L70,5 L71,5 L71,4 L71,3 L72,3 L73,3 L73,2 L74,2 L74.8421,2 L78,2 L80,2 L82,2 L84,2 L86,2 L88,2 L90,2 L92,2 L94,2 L96,2 L98,2 L100,2 L102,2 L104,2 L106,2"); 
      fill: #0031FF;   
        
    }
    
    }
    

    @keyframes x-to-plus-2 {
        
    0%, 35% { 
      d: path("M158.624 0.293091C149.425 0.436219 141.209 0.644296 140.368 0.75535C138.548 0.995902 136.852 1.79573 135.792 2.91329C134.26 4.5296 91.738 60.2551 91.2903 61.2335C90.2458 63.5168 90.2089 65.8479 91.1868 67.7834C91.9739 69.3409 111.328 94.1937 112.259 94.8424C113.455 95.6753 114.676 96.0493 116.212 96.0531C118.159 96.058 119.616 95.46 120.915 94.1229C122.643 92.3442 183.805 12.8008 184.367 11.6008C185.054 10.136 185.202 7.76622 184.712 6.07233C183.632 2.33156 180.286 -0.107226 176.386 0.00362773C175.816 0.0198649 167.823 0.150164 158.624 0.293091Z"); 
      fill: black;
       
    }
    55%, 100% { 
      d: path("M26.4895 33.8704C5.00706 34.1218 6.76617 34.0456 5.18672 34.7932C1.72224 36.4325 -0.0067974 39.8277 2.00815e-05 44.9773C0.00342882 47.5752 0.294374 49.0839 1.12089 50.7908C1.38798 51.3421 12.258 65.3468 25.2766 81.9123C38.2954 98.4779 48.9471 112.076 48.9471 112.131C48.9471 112.186 38.361 126.416 25.4224 143.753C12.484 161.091 1.65386 175.637 1.3555 176.078C0.0274925 178.041 -0.370532 181.481 0.430121 184.073C1.29734 186.88 3.72016 189.387 6.3501 190.199C7.61194 190.589 8.36648 190.61 25.8485 190.737C42.2457 190.856 44.1579 190.837 45.2876 190.544C46.9747 190.106 48.0109 189.55 49.1849 188.452C49.7255 187.947 56.6302 179.154 64.5287 168.913L78.8895 150.293L79.3294 150.757C79.5714 151.012 86.3617 159.618 94.4187 169.882C102.476 180.145 109.359 188.788 109.715 189.087C110.652 189.875 112.119 190.571 113.559 190.91C115.474 191.362 149.366 191.357 151.102 190.905C152.766 190.472 154.116 189.689 155.464 188.375C158.134 185.772 158.887 181.838 157.355 178.492C156.851 177.392 49.6762 37.9686 47.9929 36.224C46.334 34.5047 43.7668 33.6086 40.7261 33.688C40.1195 33.7039 33.7131 33.786 26.4895 33.8704Z"); 
      fill: black;   
        
    }
    
    }
    
    
 @keyframes x-to-plus-3 {
     0%, 50% { opacity: 0; }
    75%, 100% { opacity: 1; }
 }

 #final-path0 {
     animation: fadeIn0 2s infinite;
     animation-timing-function: linear;
 }

 /*  Media Query  */

 @media (max-width : 990px) {

    .prodmax-banner{
        background-size: cover;
        background-position:center;
      }

    .home-banner{
        align-items: center;
        flex-direction: column;
    }

    .banner-heading{
        width: 100%;
        position: relative;
        z-index: 10;
        margin-top: 20vh;
    }

    .animation-container{
        flex-direction: column;
        margin-left: 0;
    }

 }

 @media (max-width : 768px) {

    .prodmax-banner{
        height: 80vh;
      }
    
    .home-banner{
        height: 80vh;
    }
    
    .banner-heading{
        text-align: center;
    }

 }

 @media (max-width : 500px) {

    .banner-heading{
        margin-top: 20vh;
    }

 }

 @media (max-width : 440px) {

    .banner-heading{
        margin-top: 15vh;
    }

    .prodmax-banner{
        background-size: 400%;
      }
 }

 @media (max-width : 400px) {
    .banner-heading {
        margin-top: 18vh;
    }
}
 
@media (max-width : 320px) {
    .prodmax-banner{
        height: 100vh;
      }
}